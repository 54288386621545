import $ from 'jquery'
import { XyCheckoutApi } from './checkout-api'
import { GtmEvents } from './gtm-events'
import { CheckoutData, ILocalCartItem, IPlanItem } from './checkout-data'
import { logger } from './logger'
import { delay } from './delay'
import { showLoader, hideLoader, showErrorModal } from './modals'

console.group('XYO')
console.log('7/23/2019 - 10:48am')

function getEnvironmentFromModeParam() {
  let mode = 'prod'
  try {
    const urlParams = new URLSearchParams(window.location.search)
    const urlMode = urlParams.get('mode')
    if (urlMode) {
      mode = urlMode
    }
  } catch (ex) {
    console.error(ex) // eat this since it is only a debug tool
  }
  return mode
}

const ENV = getEnvironmentFromModeParam()

const api = XyCheckoutApi(ENV)

const REDIRECT_URLS: any = {
  local: 'http://localhost:3001',
  dev: 'https://special.coinapp.co/3-pack-sentinel30749248',
  prod: 'https://special.coinapp.co/3-pack-sentinel30749248'
}

const REDIRECT_URL = REDIRECT_URLS[ENV]
if (ENV === 'dev' || ENV === 'local') {
  $('[name="ccName"]').val('Test XYO')
  $('[name="ccNumber"]').val('4111111111111111')
  $('[name="ccCvv"]').val('123')
}

export function checkout($: any) {
  let HOOKS: any[] = []
  let BUMPS: any[] = []
  let UPSELLS: any[] = []
  let COUPONS: any[] = []

  const checkoutData = new CheckoutData()
  const gtm = new GtmEvents(checkoutData)

  window.addEventListener(
    'load',
    () => {
      const forms = document.getElementsByClassName('needs-validation')
      setupShippingToggle()
      Array.prototype.filter.call(forms, (form: any) => {
        form.addEventListener( // Loop over them and prevent submission
          'submit',
          (event: any) => {
            event.preventDefault()
            event.stopPropagation()
            if (form.checkValidity() !== false) {
              doFormAction(form.id)
            }
            form.classList.add('was-validated')
          },
          false
        )
      })
    },
    false
  )

  initCheckout()

  /*-----INITIALIZERS------*/
  async function initCheckout() {
    await initPlanReference()
    initPlansOffersAndCoupons()
    initCartWithHooks()
    loadContactInfo()
    // renderBumpOffer()
  }

  async function initPlanReference() {
    const responseData = await fetchActivePlans()

    const planData = responseData.map((plan: IPlanItem) => {
      const { id, recurringAmount, name, customFields } = plan
      const { lookupNames, queryParam, planType, msrpValue, imageUrl, cartDescription } = customFields
      let productName = ''
      if (lookupNames) {
        if (lookupNames.length !== 0) {
          productName = lookupNames[0]
        }
      }
      return {
        msrpValue,
        productName,
        cartDescription,
        imageUrl,
        queryParam,
        planType,
        description: name,
        planId: id,
        price: recurringAmount,
        quantity: 1
      }
    })

    localStorage.setItem('planData', JSON.stringify(planData))
    logger('fetch_cart_data-Local Storage', planData)
  }

  function initPlansOffersAndCoupons() {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const hookQueryParam = urlParams.get('hooks') || 'XYO-SENTINEL-X-CHAR,XY-SHIPPING-FULL-2'
      const bumpQueryParam = urlParams.get('bumps') || 'XYO-SENTINEL-X-RWB-3-PACK'
      const upsellQueryParam = urlParams.get('upsells') || 'XYO-COIN-PLUS-7D-TRIAL-24-95'
      const couponQueryParam = urlParams.get('coupons') || 'SPECIAL-20'

      HOOKS = hookQueryParam.split(',') || []
      BUMPS = bumpQueryParam.split(',') || []
      UPSELLS = upsellQueryParam.split(',') || []
      COUPONS = couponQueryParam.split(',') || []

      if (UPSELLS.includes('false')) {
        UPSELLS = []
      }

      logger('set_hooks_bumps_upsells_and_coupons-Local Storage', {
        HOOKS,
        BUMPS,
        UPSELLS,
        COUPONS,
      })
    } catch (ex) {
      // eat this since it is only a debug tool
      console.error(ex)
    }
  }

  function initCartWithHooks() {
    removeAllOrderItems()
    removeAllCartItems()
    removeAllCustomerData()
    HOOKS.forEach((plan:string) => {
      addCartItem(plan)
    })
    renderCartItems()
  }

  /*-----VIEW LOADERS------*/
  function loadContactInfo() {
    (window as any).checkoutData = checkoutData
    const data = checkoutData.getData()
    if (data) {
      if (data.contact) {
        $('#first-name-group>input').val(data.contact.firstName)
        $('#last-name-group>input').val(data.contact.lastName)
        $('#email-group>input').val(data.contact.email)
        $('#phone-group>input').val(data.contact.phone)
      }
    }
  }

  function renderBillingInfo() {
    (window as any).checkoutData = checkoutData
    const data = checkoutData.getData()
    if (data) {
      if (data.billingAddress) {
        $('#address-1-group>input').val(data.billingAddress.address)
        $('#address-2-group>input').val(data.billingAddress.address2)
        $('#city-group>input').val(data.billingAddress.city)
        $(`#country-group>select>option[value=${data.billingAddress.country}]`).selected = true
        $('#zip-group>input').val(data.billingAddress.postalCode)
      }
    }
    if (data) {
      if (data.shippingAddress) {
        $('#shipping-address-1-group>input').val(data.shippingAddress.address)
        $('#shipping-address-2-group>input').val(data.shippingAddress.address2)
        $('#shipping-city-group>input').val(data.shippingAddress.city)
        $(`#shipping-country-group>select>option[value=${data.shippingAddress.country}]`).selected = true
        $('#shipping-zip-group>input').val(data.shippingAddress.postalCode)
      }
    }
  }

  /*-----VIEW HELPERS------*/
  async function doFormAction(id: any) {
    showLoader('')

    switch (id) {
      case 'contact-info':
        submitContactInfo()
        break
      case 'billing-info':
        await submitBillingInfo()
        break
      case 'payment-info':
        await await goToUpsells()
        break
      default:
        break
    }
  }

  function setupShippingAddressRequired(value: boolean) {
    $('#shipping-address').prop('required', value)
    $('#shipping-city').prop('required', value)
    $('#shipping-country').prop('required', value)
    $('#shipping-region').prop('required', value)
    $('#shipping-postal-code').prop('required', value)
  }

  function setupShippingToggle() {
    $('#shipping-same-group').change(() => {
      const theSame = isShippingAddressTheSame()
      if (theSame) {
        $('#shipping-info').hide()
      } else {
        $('#shipping-info').show()
      }

      setupShippingAddressRequired(!theSame)

    })

    setupShippingAddressRequired(false)

    $('#shipping-info').hide()
  }

  function goToForm(id: any) {
    $('.step-forms').hide()
    $(`#${id}`).slideDown(200).show()
  }

  function isShippingAddressTheSame() {
    return $('#same-address').prop('checked')
  }

  function filterActiveSubscriptionsFromUpsells() {
    const customerData = JSON.parse(localStorage.getItem('customerData') || '{}')
    const activeSubscriptions = customerData.activeSubscriptions || []
    logger('fetch_active_subscriptions-API', activeSubscriptions)

    return UPSELLS.filter((upsell: string) => {
      const upsellItem = getPlanItem(upsell)
      const { planType } = upsellItem
      return !activeSubscriptions.includes(planType)
    }).map((upsell:string) => {
      return getPlanItem(upsell)
    })

  }

  async function goToUpsells() {
    await createOrGetCustomer()
    const upsellData = filterActiveSubscriptionsFromUpsells()

    renderUpsells(upsellData)

    hideLoader()

    if (upsellData.length > 0) {
      $('#opt-in-modal').modal('show')
    } else {
      await submitPaymentInfo()
    }
  }

  /*-----FORM SUBMISSIONS------*/
  function submitContactInfo() {
    const formData = serializeObject($('#contact-info'))

    localStorage.setItem('customerData', JSON.stringify(formData))
    logger('submit_contact_info-Local Storage', formData)

    writeCreateCustomerResponseToCheckoutData(formData, formData.phone)
    gtm.contactInfoComplete()
    logger('submit_contact_info-Gtm', formData)

    renderBillingInfo()
    goToForm('billing-info')
    hideLoader()
  }

  function handleSuccessfulAddressLookup(response) {
    console.log('handleSuccessfulAddressValidation', response)
    const { lookups } = response
    if (!lookups) {
      throw new Error('Look up error')
    }

    let hasResult = false
    let isActive = false

    lookups.forEach(({ result }) => {
      if (result.length > 0) {
        hasResult = true
        result.forEach(({ analysis }) => {
          if (analysis.active === 'Y') {
            isActive = true
          }
        })
      }
    })

    if (!hasResult) {
      throw new Error('One of your addresses cannot be validated. Please update your address.')
    }

    if (!isActive) {
      throw new Error('Suite number is invalid.')
    }
  }

  async function submitBillingInfo() {
    const formData = serializeObject($('#billing-info'))
    const customerData = JSON.parse(
      localStorage.getItem('customerData') || ''
    )
    const cartData = JSON.parse(localStorage.getItem('cartData') || '[]')

    const createOrderData: { [key: string]: any } = {
      customerId: customerData.id,
      deliveryAddress: {
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        country: formData.country,
        postalCode: formData.postalCode,
        region: formData.region
      },
      items: cartData
    }

    let shippingAddressToValidate:object|undefined = undefined

    if (!isShippingAddressTheSame()) {
      createOrderData.shippingInfo = {
        address: formData.shippingAddress,
        address2: formData.shippingAddress2,
        city: formData.shippingCity,
        country: formData.shippingCountry,
        postalCode: formData.shippingPostalCode,
        region: formData.shippingRegion
      }
      shippingAddressToValidate = {
        street: createOrderData.shippingInfo.address,
        street2: createOrderData.shippingInfo.address2,
        city: createOrderData.shippingInfo.city,
        state: createOrderData.shippingInfo.region,
        zipCode: createOrderData.shippingInfo.postalCode
      }
    } else {
      createOrderData.shippingInfo = {
        address: formData.address,
        address2: formData.address2,
        city: formData.city,
        country: formData.country,
        postalCode: formData.postalCode,
        region: formData.region
      }
    }

    const win = window as any
    win.createOrderData = createOrderData

    const billingAddressToValidate = {
      street: createOrderData.deliveryAddress.address,
      street2: createOrderData.deliveryAddress.address2,
      city: createOrderData.deliveryAddress.city,
      state: createOrderData.deliveryAddress.region,
      zipCode: createOrderData.deliveryAddress.postalCode
    }

    const billingAddressValid = true
    const shippingAddressValid = true
    try {
      // await api.fetchAddressValidation(billingAddressToValidate, handleSuccessfulAddressLookup)
      // billingAddressValid = true

      // if (shippingAddressToValidate) {
      //   shippingAddressValid = false
      //   await api.fetchAddressValidation(shippingAddressToValidate, handleSuccessfulAddressLookup)
      //   shippingAddressValid = true
      // }

      localStorage.setItem('orderData', JSON.stringify(createOrderData))
      logger('fetch_cart_data-Local Storage', createOrderData)

      writeOrderDataToCheckoutData(createOrderData) // This is not going to contain a customer ID yet
      writeCartItemsToCheckoutData(createOrderData)
      gtm.orderDataComplete()
      logger('fetch_cart_data-GTM', createOrderData)

      goToForm('payment-info')
      hideLoader()
    } catch (error) {
      console.log('billingAddressValid', billingAddressValid)
      console.log('shippingAddressValid', shippingAddressValid)

      if (!billingAddressValid) {
        $('#address2')[0].setCustomValidity('Suite Number is required')
      }
      if (!shippingAddressValid) {
        $('#shippingAddress2')[0].setCustomValidity('Suite Number is required')
      }
      hideLoader()
      showErrorModal(error, 'validation')
    }
  }

  function setHookAndBumpOrderCoupons() {
    let orderData = JSON.parse(localStorage.getItem('orderData') || '{}')
    const upsellOrderData = JSON.parse(localStorage.getItem('upsellOrderData') || '{}')
    if (upsellOrderData.items) {
      orderData = {
        ...orderData,
        couponIds: COUPONS
      }
    }
    localStorage.setItem('orderData', JSON.stringify(orderData))
    logger('set_hook_and_bump_order_coupons-Local Storage', orderData)
  }

  async function submitPaymentInfo() {
    const formData = serializeObject($('#payment-info'))
    setHookAndBumpOrderCoupons()
    try {
      await createHookAndBumpOrder()
      await delay(500) // Rebilly throttles order creation by 500ms, so we need to wait before sending the second order.

      await createUpsellOrder()

      await createPaymentToken(formData)

      const transactionData = await createTransaction()
      logger('create_transaction-API', transactionData)

      showLoader('Redirecting to secure confirmation page')

      localStorage.setItem('transactionData', JSON.stringify(transactionData))
      logger('create_transaction-Local Storage', transactionData)

      const orderData = JSON.parse(localStorage.getItem('orderData') || '{}')
      const upsellOrderData = JSON.parse(localStorage.getItem('upsellOrderData') || '{}')

      writeTransactionDataToCheckoutData(transactionData, { orderData, upsellOrderData })
      logger('create_transaction-GTM', transactionData)

      gtm.transactionComplete(() => {
        const queryParams = getCartItemQueryParams()
        setTimeout(() => {
          window.location.href = `${REDIRECT_URL}?${queryParams}`
        },         1000)
      })

    } catch (error) {
      hideLoader()
      const message = error.message || error
      showErrorModal(message)
    }
  }

  /*----- API REQUESTS ------*/
  async function fetchActivePlans() {
    let planData = []
    try {
      planData = await api.fetchActivePlans()
      logger('fetch_cart_data-API', planData)
    } catch (error) {
      const message = error.message || error
      showErrorModal(message)
    } finally {
      hideLoader()
    }
    return planData
  }

  async function createOrGetCustomer() {
    showLoader('Submitting contact information...')
    const customerData = JSON.parse(localStorage.getItem('customerData') || '{}')

    try {
      const responseData = await api.fetchOrCreateCustomer(customerData)
      logger('fetch_or_create_customer-API', responseData)

      localStorage.setItem('customerData', JSON.stringify(responseData))
      logger('fetch_or_create_customer-Local Storage', responseData)

      writeCreateCustomerResponseToCheckoutData(responseData, customerData.phone)
      gtm.contactInfoComplete()
      logger('fetch_or_create_customer-GTM', { responseData, customerData })
    } catch (error) {
      const message = error.message || error
      showErrorModal(message)
    } finally {
      hideLoader()
    }
  }

  async function createHookAndBumpOrder() {
    showLoader('Creating your order...')
    const orderData = JSON.parse(localStorage.getItem('orderData') || '{}')
    const customerData = JSON.parse(localStorage.getItem('customerData') || '{}')
    const cartData = JSON.parse(localStorage.getItem('cartData') || '[]')

    if (!orderData) {
      return
    }

    orderData.customerId = customerData.id
    orderData.items = cartData

    const response = await api.createOrder(orderData)

    let orderDataResponse:any = {}
    if (response.status > 400) {
      const json = await response.json()
      showErrorModal(`${json.message}: ${json.status}`)
      throw new Error(`${ json.message }: ${ json.status }`)
    } else {
      orderDataResponse = await response.json()
      logger('create_hook_and_bump_order-API', orderDataResponse)

      localStorage.setItem('orderData', JSON.stringify(orderDataResponse))
      logger('create_hook_and_bump_order-Local Storage', orderDataResponse)

      localStorage.setItem('cartData', JSON.stringify(orderDataResponse.items))
      renderCartItems()

      writeOrderDataToCheckoutData(orderDataResponse)
      writeCartItemsToCheckoutData(orderDataResponse)
      gtm.orderDataComplete()
      logger('create_hook_and_bump_order-GTM', orderDataResponse)

      goToForm('payment-info')
    }
  }

  async function createUpsellOrder() {
    showLoader('Adding items to your order...')
    const upsellOrderData = JSON.parse(localStorage.getItem('upsellOrderData') || '{}')
    const { items } = upsellOrderData
    if (!items) {
      return
    }

    items.forEach((item) => {
      const { planId } = item
      addCartItem(planId)
    })

    renderCartItems()

    const response = await api.createOrder(upsellOrderData)
    let orderDataResponse = {}
    if (response.status > 400) {
      const json = await response.json()
      throw new Error(`${json.message}: ${json.status}`)
    } else {
      orderDataResponse = await response.json()
      logger('create_upsell_order-API', orderDataResponse)

      localStorage.setItem('upsellOrderData', JSON.stringify(orderDataResponse))
      logger('create_upsell_order-Local Storage', orderDataResponse)

      writeOrderDataToCheckoutData(orderDataResponse)
      writeCartItemsToCheckoutData(orderDataResponse)
      gtm.orderDataComplete()
      logger('create_upsell_order-GTM', orderDataResponse)

      goToForm('payment-info')
    }
  }

  async function createPaymentToken(formData: any) {
    showLoader('Securely submitting your payment information...')
    const orderData = JSON.parse(localStorage.getItem('orderData') || '{}')
    try {
      const tokenData: any = await api.createPaymentToken(formData, orderData)
      logger('create_payment_token-API', { tokenData })

      localStorage.setItem('tokenData', JSON.stringify(tokenData))
      logger('create_payment_token-Local Storage', { tokenData })

    } catch (error) {
      const message = error.message || error
      showErrorModal(message)
      throw new Error(message)
    } finally {
      hideLoader()
    }
  }

  async function createTransaction() {
    showLoader('Finalizing your transaction')
    const tokenData = JSON.parse(localStorage.getItem('tokenData') || '{}')
    const orderData = JSON.parse(localStorage.getItem('orderData') || '{}')
    const upsellOrderData = JSON.parse(localStorage.getItem('upsellOrderData') || '{}')

    let returnData = {}
    try {
      returnData = await api.createTransaction(tokenData, orderData, upsellOrderData)
    } catch (error) {
      const message = error.message || error
      showErrorModal(message)
      hideLoader()
      throw new Error(message)
    }
    return returnData
  }

  /*-----DATA HANDLERS------*/
  function getPlanItem(planIdSearch) {
    const planData = JSON.parse(localStorage.getItem('planData') || '[]')
    const returnData = planData.find((plan: { planId: string }) => (planIdSearch === plan.planId))
    return returnData || {}
  }

  function getCartItemQueryParams() {
    const cartData = JSON.parse(
      localStorage.getItem('cartData') || '[]'
    )

    return cartData.map((cartItem: any) => {
      const { planId } = cartItem
      const itemData = getPlanItem(planId)
      const { queryParam } = itemData
      return queryParam
    }).join('&')
  }

  function addCartItem(productId: string) {
    removeCartItem(productId) // remove it so that we don't duplicate it

    const cartData = JSON.parse(
      localStorage.getItem('cartData') || '[]'
    )

    const itemData = getPlanItem(productId)

    cartData.push(itemData)

    localStorage.setItem('cartData', JSON.stringify(cartData))
  }

  function removeAllCartItems() {
    localStorage.setItem('cartData', '[]')
  }

  function removeAllCustomerData() {
    localStorage.setItem('customerData', '{}')
  }

  function removeAllOrderItems() {
    localStorage.setItem('orderData', '{}')
    localStorage.setItem('upsellOrderData', '{}')
  }

  function removeCartItem(planIdToRemove: string) {
    const cartData = JSON.parse(
      localStorage.getItem('cartData') || '[]'
    )

    let filteredCartData = []

    filteredCartData = cartData.filter((item: ILocalCartItem) => {
      const { planId } = item
      return (planId !== planIdToRemove)
    })

    localStorage.setItem('cartData', JSON.stringify(filteredCartData))
  }

  /*-----RENDERS------*/
  function renderCartItems() {
    const cartData = JSON.parse(
      localStorage.getItem('cartData') || '[]'
    )

    const orderData = JSON.parse(
      localStorage.getItem('orderData') || '{}'
    )

    const { discounts } = orderData
    let totalPrice = 0
    let html = cartData.map((cartItem: ILocalCartItem) => {
      const { description, price, quantity, msrpValue, imageUrl, cartDescription } = cartItem
      console.log(imageUrl)
      const productDescription = cartDescription || description

      let msrpHtml = ''
      if (msrpValue) {
        msrpHtml = `
          <span class='text-muted font-weight-light mr-2'><del>$${msrpValue}</del></span>
        `
      }

      let imageHtml = ''
      if (imageUrl) {
        imageHtml = `<div class="text-center"><img class="mb-3 p-1 shadow-sm border rounded" style="height:100px" src="${imageUrl}"></div>`
      }

      totalPrice += price
      return `
      <li class='list-group-item justify-content-between lh-condensed remove-item'>
      ${imageHtml}
        <div class='row mt-3'>
          <div class='col-8'>
            <h6 class='mb-4 text-dark'>${productDescription}</h6>
          </div>
          <div class='col-4 text-right'>
            <div class='text-dark'><b>${msrpHtml}$${price}</b></div>
          </div>
        </div>
      </li>
    `
    })

    html = html.join('')
    let discountHtml = []
    if (discounts) {
      discountHtml = discounts.map((discountItem: { amount: number, description: string }) => {
        const { amount, description } = discountItem
        totalPrice -= amount
        return `
        <li class='list-group-item justify-content-between lh-condensed remove-item'>
          <div class='row'>
            <div class='col-12'>
              <h6 class='mb-4 text-success'>${description}</h6>
            </div>
          </div>
          <div class='row'>
            <div class='col-9'>
              <small class='text-success'>DISCOUNT</small>
            </div>
            <div class='col-3 text-right'>
              <div class='text-dark font-weight-bold text-success'>$${amount}</div>
            </div>
          </div>
        </li>
      `
      })
    }
    html += discountHtml.join('')

    html += `
    <li class='list-group-item d-flex justify-content-between'>
      <span>Total (USD)</span>
      <strong>$${totalPrice.toFixed(2)}</strong>
    </li>
    `
    $('#cart-container').html(html)
    $('#cart-counter').html(cartData.length)
  }

  function renderBumpOffer() {
    const html = BUMPS.map((bump:string) => {
      return `<div class="row">
        <div class="col-3">
          <label class="disable-selection">
            <div>
              <small class="font-weight-bold text-info"><b>Click to add Item</b></small>
            </div>
            <div>
              <i class="fa fa-level-up-alt rotate-270 mt-2 mr-3 ml-3 text-muted"></i>
              <input class="product-toggle" type="checkbox" name="${bump}">
            </div>
          </label>
        </div>
        <div class="col-4 text-center">
          <img class="w-75" src="https://special.coinapp.co/hosted/images/2c/cb628fe80a4e04bae60c78c97e1ba7/web_optimized_red_white_blue.png">
          <div class="text-muted">
            <small><b>Normal Price: $150.00</b></small>
          </div>
          <div class="text-success">
            <b>PRICE NOW: $67</b>
          </div>
        </div>
        <div class="col-5">
          <b class="text-muted"><u>Earn Even More Money Geomining</u></b>
          <div><small><i class="fa fa-check mr-1 text-success" style="font-size:10px"></i> 3 Additional Sentinel X's</small></div>
          <div><small><i class="fa fa-check mr-1 text-success" style="font-size:10px"></i> Have Your Friends Use Them So You Both Earn Money!</small></div>
          <div><small><i class="fa fa-check mr-1 text-success" style="font-size:10px"></i> Earn a % of What Your Friends Earn When Using It!</small></div>
          <div><small><i class="fa fa-check mr-1 text-success" style="font-size:10px"></i> Red, White and Blue</small></div>
        </div>
      </div>`
    })

    $('#bump-offer-group').html(html.join(''))
  }

  function renderUpsells(upsellData) {
    const html = upsellData.map((upsell: { description: string, planType: string, planId:string }, idx:number) => {

      const { description, planId } = upsell

      let className = 'btn-info'
      if (idx + 1 ===  upsellData.length) {
        className = 'btn-secondary'
      }else {
        className = 'btn-primary'
      }
      return `
        <div data-optin="${planId}" data-dismiss="modal" class="btn btn-block opt-in-btn ${className}">
          ${description}
        </div>
      `
    })

    html.push(`
      <div data-dismiss="modal" class="btn btn-block opt-in-btn btn-dark">
        <b>No Thanks</b><br>Complete my one-time purchase
      </div>
    `)

    $('#optin-options-container').html(html.join(''))
  }

  /*-----UTILS------*/
  function serializeObject(obj: any) {
    const returnObject: any = {}
    const array = obj.serializeArray()

    array.forEach((item: any) => {
      if (returnObject[item.name] !== undefined) {
        // Did we already create this property?
        if (!returnObject[item.name].push) {
          returnObject[item.name] = [obj[item.name]]
        }
        returnObject[item.name].push(item.value || '')
      } else {
        returnObject[item.name] = item.value || ''
      }
    })

    return returnObject
  }

  /*-----WRITERS------*/
  function writeCreateCustomerResponseToCheckoutData(response: any, phone: string) {
    checkoutData.setCustomerId(response.id)
    checkoutData.setContact({
      phone,
      firstName: response.firstName,
      lastName: response.lastName,
      email: response.email
    })
    checkoutData.save()
  }

  function writeOrderDataToCheckoutData(orderData: any) {
    // Rebilly uses 'Devlivery' as magic Payment/Shipping Address
    const deliveryAddress = orderData.deliveryAddress
    if (deliveryAddress) {
      checkoutData.setBilling({
        address: deliveryAddress.address,
        address2: deliveryAddress.address2,
        city: deliveryAddress.city,
        country: deliveryAddress.country,
        region: deliveryAddress.region,
        postalCode: deliveryAddress.postalCode
      })
    }

    const shippingAddress = orderData.shippingAddress
    if (shippingAddress) {
      checkoutData.setShipping({
        address: shippingAddress.address,
        address2: shippingAddress.address2,
        city: shippingAddress.city,
        country: shippingAddress.country,
        region: shippingAddress.region,
        postalCode: shippingAddress.postalCode
      })
    }

    checkoutData.save()

  }

  function writeCartItemsToCheckoutData(orderData: any) {
    checkoutData.clearItems()
    if (orderData.items) {
      for (const { planId, quantity, price } of orderData.items) {
        checkoutData.addItem({
          planId,
          quantity,
          price
        })
      }
    }
    checkoutData.save()
  }

  function writeTransactionDataToCheckoutData(transactionData: any, orderData: any) {
    let amount = orderData.orderData.amount || 0

    if (orderData.upsellOrderData && orderData.orderData) {
      const upsellAmount = orderData.upsellOrderData.amount || 0
      const orderAmount = orderData.orderData.amount || 0
      amount = orderAmount + upsellAmount
    } else if (orderData.upsellOrderData && !orderData.orderData) {
      const upsellAmount = orderData.upsellOrderData.amount || 0
      amount = upsellAmount
    }
    checkoutData.setTransaction({
      total: amount,
      id: transactionData.transactionId,
      method: transactionData.method || 'payment-card'
    })
    checkoutData.save()
  }

  /*-----BINDING------*/
  $('body').on('change deselect', '.product-toggle', async(e: { target: object }) => {
    const checked: boolean = $(e.target).is(':checked') || false
    const planId: any = $(e.target).attr('name') || ''
    if (checked) {
      addCartItem(planId)
      const cartData = JSON.parse(localStorage.getItem('cartData') || '[]')
      logger('set_cart_from_bump_add_item-Local Storage', cartData)
    } else {
      removeCartItem(planId)
      const cartData = JSON.parse(localStorage.getItem('cartData') || '[]')
      logger('set_cart_from_bump_remove_item-Local Storage', cartData)
    }
    renderCartItems()
  })

  $('body').on('change', '.product-selection', async(e: { target: object }) => {
    const fieldName: any = $(e.target).attr('name') || ''
    const $selector: string | number = $(`[name='${fieldName}']`)

    $.each($selector, (idx: number, obj: any) => {
      const removeProduct = $(obj).attr('value')
      removeCartItem(removeProduct)
    })

    const addProductName = $(e.target).val()
    if (addProductName) {
      addCartItem(addProductName)
    }
    renderCartItems()
  })

  $('body').on('click touchstart', '.remove-cart-item', async(e: { target: object }) => {
    const planId = $(e.target).attr('planId')
    removeCartItem(planId)
    renderCartItems()
  })

  $('body').on('click touchstart', '.back-to-button', (e: any) => {
    const backTo: any = $(e.target).data('back-to') || ''
    goToForm(backTo)
  })

  $('body').on('click touchstart', '.opt-in-btn', async(e: any) => {
    $('#opt-in-modal').modal('hide')

    const optInVal: any = $(e.target).data('optin') || ''

    if (optInVal) {
      const upsellOrderItem = getPlanItem(optInVal)
      const orderData = JSON.parse(localStorage.getItem('orderData') || '[]')
      const customerData = JSON.parse(localStorage.getItem('customerData') || '{}')

      const upsellOrderData = {
        ...orderData,
        customerId:customerData.id,
        items: [upsellOrderItem]
      }

      localStorage.setItem('upsellOrderData', JSON.stringify(upsellOrderData))
      renderCartItems()
    }

    await submitPaymentInfo()
  })

  $('#payment-info input').keyup(() => {
    $('#address2')[0].setCustomValidity('')
    $('#shippingAddress2')[0].setCustomValidity('')
  })
}
