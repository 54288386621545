import { core, usStreet } from 'smartystreets-javascript-sdk'
const { Lookup } = usStreet

// tslint:disable-next-line: variable-name
export const XyCheckoutApi = (envIn: string = 'prod') => {
  let env = envIn

  if (typeof env !== 'string' || !env) { // This is left in for library usage when outside of typescript
    env = 'prod'
  }

  const REBILLY_KEYS: any = {
    local: 'pk_sandbox_uSCFz8SZ9mExc-kc9rQvMNSo9TycKuS0PtOHoyO',
    dev: 'pk_sandbox_uSCFz8SZ9mExc-kc9rQvMNSo9TycKuS0PtOHoyO',
    prod: 'pk_live_bcB8awIEGclaiQcA_-08xouZstyl2bP5iH51a7P'
  }

  const SMARTY_STREETS_ID = '21102174564513388'
  const SMARTY_STREETS_TOKEN = '28532905136301324'

  const API_URLS: any = {
    local: 'http://localhost:3000',
    dev: 'https://dev-checkout-api.xy.company',
    prod: 'https://production-checkout-api.xy.company'
  }

  const REBILY_URLS: any = {
    local: 'https://api-sandbox.rebilly.com/v2.1',
    dev: 'https://api-sandbox.rebilly.com/v2.1',
    prod: 'https://api.rebilly.com/v2.1'
  }

  const API_URL = API_URLS[env]
  const REBILLY_URL = REBILY_URLS[env]
  const REBILLY_KEY = REBILLY_KEYS[env]

  return {
    fetchActivePlans: async() => {
      const fetchOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        }
      }

      const response = await fetch(`${API_URL}/products/plans/active`, fetchOptions)

      if (response.status > 400) {
        throw Error(await response.json())
      }

      return await response.json()
    },
    createPaymentToken: async(formData: any, orderData: any) => {
      const { method, ccNumber, ccExpMonth, ccExpYear, ccCvv } = formData

      const { billingAddress } = orderData

      const payloadData = {
        method,
        billingAddress,
        leadSource: {
          medium: 'social',
          source: 'facebook-ads',
          campaign: 'sentinelx',
          term: '{{placement}}',
          content: '{{campaign.name}}',
          affiliate: '{{ad.name}}',
          subAffiliate: 'paid',
          salesAgent: 'virtual',
          clickId: 'sight',
          path: '',
          currency: '',
          amount: ''
        },
        paymentInstrument: {
          pan: ccNumber,
          expMonth: ccExpMonth,
          expYear: ccExpYear,
          cvv: ccCvv
        }
      }

      const fetchOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(payloadData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: REBILLY_KEY
        }
      }

      const response = await fetch(`${REBILLY_URL}/tokens`, fetchOptions)
      let responseData: any = {}
      if (response.status !== 200) {
        responseData = await response.json()
        if (responseData.invalidFields) {
          const invalidFieldMessage = responseData.invalidFields.map((field: { message: string }) => {
            return field.message
          })
          responseData.message = invalidFieldMessage.join('/n')

          throw Error(responseData.message)
        }
      }
      return responseData
    },
    createTransaction: async(tokenData: any, orderData: any, upsellOrderData: any) => {
      const orderInvoiceId = orderData.id
      const upsellOrderInvoiceId = upsellOrderData.id

      let invoiceId: any []|string = ''

      if (orderInvoiceId && upsellOrderInvoiceId) {
        invoiceId = [orderInvoiceId, upsellOrderInvoiceId]
      } else if (!orderInvoiceId && upsellOrderInvoiceId) {
        invoiceId = upsellOrderInvoiceId
      } else {
        invoiceId = orderInvoiceId
      }
      const payloadData = {
        invoiceId,
        paymentToken: tokenData.id,
        url: ''
      }

      const fetchOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(payloadData),
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const response = await fetch(`${API_URL}/transactions`, fetchOptions)
      const responseData = await response.json()
      if (response.status > 400) {
        throw Error(responseData.message || 'Error creating transaction')
      }

      return responseData
    },
    fetchOrCreateCustomer: async(formData: any) => {
      const fetchOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      }

      const response = await fetch(`${API_URL}/customers`, fetchOptions)

      if (response.status !== 200) {
        const errorData = await response.json()
        const errorMsg = errorData.message || errorData
        throw Error(errorMsg)
      }

      return await response.json()
    },
    createOrder: async(formData: any) => {
      const fetchOptions: RequestInit = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return fetch(`${API_URL}/orders`, fetchOptions)
    },
    updateOrder: async(formData: any) => {
      const fetchOptions: RequestInit = {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json'
        }
      }

      return fetch(`${API_URL}/orders`, fetchOptions)
    },
    fetchAddressValidation: async(address, handleSuccess) => {
      const { street, city, state, zipCode, street2 } = address
      const clientBuilder = new core.ClientBuilder(new core.StaticCredentials(SMARTY_STREETS_ID, SMARTY_STREETS_TOKEN))
      const client = clientBuilder.buildUsStreetApiClient()
      const lookup = new Lookup()

      lookup.street = street
      lookup.street2 = street2
      lookup.city = city
      lookup.state = state
      lookup.zipCode = zipCode
      lookup.maxCandidates = 10

      await client.send(lookup).then(handleSuccess).catch(handleAddressValidationError)
    }
  }
}

function handleAddressValidationError(response) {
  console.log(response)
  throw new Error(response)
  console.error(response)
}
