export function orderTimer($: any) {
  const hour = 0
  let minute = 15
  let second = 0

  const updateTimer = () => {
    if (typeof $ !== 'undefined') {
      if (hour < 10) {
        $('.hour').html(`0${hour.toString()}`)
      } else {
        $('.hour').html(hour.toString())
      }
      if (minute < 10) {
        $('.minute').html(`0${minute.toString()}`)
      } else {
        $('.minute').html(minute.toString())
      }
      if (second < 10) {
        $('.second').html(`0${second.toString()}`)
      } else {
        $('.second').html(second.toString())
      }
      second--
      if (second < 0) {
        second = 59
        minute--
        if (minute < 0) {
          minute = 15
        }
      }
    }
  }

  setTimeout(() => {
    updateTimer()
  },         0)

  setInterval(() => {
    updateTimer()
  },          1000)
}
