import { CheckoutData } from './checkout-data'

export class GtmEvents {

  private checkoutData: CheckoutData

  constructor(checkoutData: CheckoutData) {
    this.checkoutData = checkoutData
  }

  public push(event: string, eventCallback?: () => void) {
    const win: any = window
    win.dataLayer.push(
      {
        event, checkoutData: this.checkoutData.getData()
      },
      eventCallback
    )
  }

  public contactInfoComplete(callback?: () => void) {
    this.push('contact-info-complete', callback)
  }

  public orderDataComplete(callback?: () => void) {
    this.push('order-data-complete', callback)
  }

  public transactionComplete(callback?: () => void) {
    this.push('transaction-complete', callback)
  }
}
