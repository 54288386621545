export class Cookies {
  static setCookie(cookieTitle: string, cookieContent: string, lifespanInDays: number, domainName: string) {
    document.cookie = `${cookieTitle}=${encodeURIComponent(cookieContent)
    }; max-age=${60 * 60 * 24 * lifespanInDays
    }; path=${'/'
    }; domain=${domainName}`
  }

  static getCookie(cookieTitle: string) {
    const cookieString = document.cookie
    if (cookieString.length !== 0) {
      const cookieArray = cookieString.split('; ')
      for (let i = 0; i < cookieArray.length; i++) {
        const cookieValue = cookieArray[i].match(`${cookieTitle}=(.*)`)
        if (cookieValue != null) {
          return decodeURIComponent(cookieValue[1])
        }
      }
    }
    return null
  }
}
