import $ from 'jquery'

export function showLoader(message: any) {
  const loadingMsg = message || 'Securely Submitting Information'

  $('#loading-message').text(loadingMsg)
  $('#submitting-modal').modal('show')
}

export function hideLoader() {
  $('#submitting-modal').modal('hide')
}

export function showErrorModal(error: string, type?: string) {
  $('#errorModal').modal('show')
  if (type === 'validation') {
    $('#errorModal .modal-header').addClass('bg-light')
    $('#errorModal .modal-title').text('Oops!')
  }
  console.log(error)

  const errorMsg = error.toString().replace('Error: Error:', 'Error:')
  $('#errorMessage').text(errorMsg)
  console.error('error modal', error)
}
