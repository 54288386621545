let target = document.getElementById('randomizedText')
let titles = [
  'Gripe or be griped.',
  'Believe in the Gripe.',
  'Go Gripe yourself.',
  'It’s your world for the griping.',
  'I’m with Gripist.',
  'Put that in your Gripe and smoke it.',
  'You’re Griped.',
  'Get a Gripe on it.',
  'Carpe Gripe em’.',
  'Hold on for dear Gripe.',
  'Hold your Gripe.',
  'Practice makes Gripist.',
  'Jack of all trades, master of Gripe.',
  'Squeaky wheel gets the Gripe.',
  'Don’t put all your Gripes in one basket.',
  'You can lead a horse to water, but you can’t make it Gripe.',
  'Two is company, three is a Gripe.',
  'Griping never strikes twice.',
  'Gripe or die trying.'
]

function newTitle() {
  const i = (Math.random() * titles.length) | 0
  if (target) {
    target.innerText = titles[i]
  }
}

newTitle()
