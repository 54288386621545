function getEnvironmentFromModeParam() {
  let mode = 'prod'
  try {
    const urlParams = new URLSearchParams(window.location.search)
    const urlMode = urlParams.get('mode')
    if (urlMode) {
      mode = urlMode
    }
  } catch (ex) {
        // eat this since it is only a debug tool
    console.error(ex)
  }
  return mode
}

const ENV = getEnvironmentFromModeParam()

export const logger = (param1, param2) => {
  if (ENV === 'dev' || ENV === 'local') {
    console.warn(param1, param2)
  }
}
