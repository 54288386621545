export interface ILocalCartItem {
  sku?:string,
  msrpValue:number,
  displayName:string,
  productName:string,
  price:number,
  quantity: number,
  description:string,
  planId:string,
  cartDescription:string,
  imageUrl: string
}

export interface IAddress {
  address: string
  address2: string
  city: string
  country: string
  region: string
  postalCode: string
}

export interface ICartItem {
  planId: string,
  quantity: string,
  price: number
}

export interface ITransaction {
  method: string,
  id: string,
  total: number
}

export interface IContact {
  firstName: string
  lastName: string
  email: string,
  phone: string
}

export interface IPlanItem {
  id: string,
  recurringAmount: number,
  name: string,
  customFields: {
    msrpValue: number,
    lookupNames: string[],
    queryParam: string,
    planType: string,
    cartDescription: string,
    imageUrl: string
  }
}

export interface ICheckoutData {
  customerId?: string
  contact?: IContact
  shippingAddress?: IAddress
  billingAddress?: IAddress
  items: ICartItem[]
  transaction?: ITransaction
}

export class CheckoutData {
  public data: ICheckoutData
  public name: string

  constructor(initData: ICheckoutData = { items:[] }, name = 'checkout-data') {
    this.data = initData
    this.name = name
    this.load()
  }

  public save() {
    localStorage.setItem(this.name, JSON.stringify(this.data))
  }

  public load() {
    this.data = JSON.parse(localStorage.getItem(this.name) || '{ }')
  }

  public clear() {
    this.data = { items:[] }
    this.save()
  }

  public addItem(item: ICartItem) {
    this.data.items.push(item)
  }

  public clearItems() {
    this.data.items = []
  }

  public getData() {
    return this.data
  }

  public setCustomerId(id: string) {
    this.data.customerId = id
  }

  public setContact(contact: IContact) {
    this.data.contact = contact
  }

  public setShipping(address: IAddress) {
    this.data.shippingAddress = address
  }

  public setBilling(address: IAddress) {
    this.data.billingAddress = address
  }

  public setTransaction(transaction: ITransaction) {
    this.data.transaction = transaction
  }
}
