import $ from 'jquery'
import 'bootstrap'
import './randomizedText'
import { checkout } from './checkout'
import { orderTimer } from './order-timer'
import lozad from 'lozad'
import countryRegionSelector from 'country-region-selector'
import { CookiesPolicy } from './cookiesPolicy'

function init() {
  const lazyLoad = lozad()
  lazyLoad.observe()
  countryRegionSelector.init()
  const cookiesPolicy = new CookiesPolicy()
  checkout($)
  orderTimer($)
}
init()
