import $ from 'jquery'
import { Cookies } from './cookies'

export class CookiesPolicy extends Cookies {

  private cookieName: string
  private domain: string

  constructor(cookieName = 'cookie-policy', domain = window.location.hostname) {
    super()

    this.cookieName = cookieName
    this.domain = domain

    if (Cookies.getCookie(this.cookieName) !== 'true') {
      this.show()
    }

    $(document).ready(() => {
      this.init()
    })
  }

  private init() {
    $('.cookies-close').click((e) => {
      e.preventDefault()
      Cookies.setCookie(this.cookieName, 'true', 365, this.domain)
      this.hide()
    })
  }

  private hide() {
    $('.cookie-policy').hide()
  }

  private show() {
    $('.cookie-policy').show()
  }

}
